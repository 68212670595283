import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

// TODO: If links or buttons are clicked or receive focus inside the draggable, auto-scroll to them!

export default el => {

    const target = el.querySelector('[data-draggable]') || el.firstElementChild;

    let observer;
    let draggable;

    const createDraggable = () => {
        if (draggable) {
            return;
        }
        [draggable] = Draggable.create(target, {
            bounds: el,
            type: 'x',
            inertia: true,
            allowNativeTouchScrolling: true,
            allowContextMenu: true,
            liveSnap: false
        });
    };

    const onResize = () => {
        if (!draggable) {
            return;
        }
        draggable.applyBounds(el);
    };

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            createDraggable();
            Viewport.on('resize', onResize);
            observer.disconnect();
            observer = null;
        });
        observer.observe(el);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        if (draggable) {
            draggable.kill();
            draggable = null;
            Viewport.off('resize', onResize);
        }
    };

    return {
        init,
        destroy
    };

};
